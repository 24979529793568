import React from 'react'

import Nbiot649m from './nbiot649m/Nbiot649m'
import GidrolockW1 from './gidrolockW1/GidrolockW1'
import GidrolockC1 from './gidrolockC1/GidrolockC1'
import GidrolockC2 from './gidrolockC2/GidrolockC2'

export default function LeakageSensor(props) {
    switch (props.TEMPLATE) {
        /*case 'nbiot649m':
            return <Nbiot649m {...props} />*/
        case 'gidrolock_w1':
            return <GidrolockW1 {...props} />
        case 'gidrolock_с1':
            return <GidrolockC1 {...props} />
        case 'gidrolock_с2':
            return <GidrolockC2 {...props} />
        default:
            return <Nbiot649m {...props} />
    }
}