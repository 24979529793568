export function secondsToDhms(seconds) {
    if (seconds === null) {
        return "-"
    }

    const d = Math.floor(seconds / (3600 * 24))
    const h = Math.floor(seconds % (3600 * 24) / 3600)
    const m = Math.floor(seconds % 3600 / 60)
    const s = Math.floor(seconds % 60)

    const dDisplay = d > 0 ? `${d} дн ` : ""
    const hDisplay = h > 0 ? `${h} ч ` : ""
    const mDisplay = m > 0 ? `${m} мин ` : ""
    const sDisplay = s > 0 ? `${s} c` : ""

    return (dDisplay + hDisplay + mDisplay + sDisplay).trim()
}