import React from 'react'

import { Image } from 'react-bootstrap'

import imgGood from '../../images/signal/good.png'
import imgMedium from '../../images/signal/medium.png'
import imgBad from '../../images/signal/bad.png'

export function getSignalImg(signal) {
    if (signal === undefined || signal.rssi === undefined) {
        return imgBad
    } else if (signal.rssi >= -80 && (signal.snr === null || signal.snr >= 20)) {
        return imgGood
    } else if (signal.rssi >= -90 && (signal.snr === null || signal.snr >= 13)) {
        return imgGood
    } else if (signal.rssi >= -100 && (signal.snr === null || signal.snr >= 0)) {
        return imgMedium
    } else {
        return imgBad
    }
}

export default function SignalState(props) {
    return (
        <Image className={props.className} src={getSignalImg(props.signal)} style={{ position: 'absolute', ...props.style }} />
    )
}