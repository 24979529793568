import axios from 'axios'
import { toast } from 'react-toastify'


export const CANCEL = 'API is canceled'

const AQuery = async (method, url, datain, cancelToken = null) => {
    //document.getElementById('loader').style.visibility = 'visible'
    /*if (method === 'GET') {
        url = url + '?'
        for (var prop in datain) {
            url = url + '' + prop + '=' + datain[prop] + '&'
        }
    }*/

    /*if (token) {
        headers.Authorization = 'Bearer ' + localStorage.token
    }*/

    try {
        const response = await axios({
            method: method,
            url: `${process.env.REACT_APP_API_URL}/${url}`,
            data: datain,
            cancelToken,
            responseType: 'json'
        })

        
        //document.getElementById('loader').style.visibility = 'hidden'


        if (response.data.status === "ok") {
            return response
        } else if (response.data.message) {
            return response
        }

        if (response.data.errors.message) {
            toast.error(response.data.errors.message, 'Ошибка', 2000)
            return
        }

        return response

    } catch (e) {
        //document.getElementById('loader').style.visibility = 'hidden'

        if (e.response === undefined) {
            toast.error('Сервер недоступен или отсутствует интернет соединение', 'Ошибка сети', 2000)
            return
        }

        try {

            if (e.message === CANCEL) {
                return
            }

            switch (e.response.status) {
                case 500: toast.error('Внутренняя ошибка сервера', '500', 2000)
                    break
                case 401:
                    toast.error('Сессия завершена. Для продолжения необходимо авторизоваться.', '401', 2000)
                    //setTimeout(() => { window.location.href = '/logout' }, 2000)
                    break
                default: toast.error('Ошибка сервера', e.response.status, 2000)
            }

        } catch (e) {
            console.log(e)
            toast.error('Неизвестная ошибка сервера', 'Ошибка', 2000)
        }
    }
}

export default AQuery