import React from 'react'

import CounterDefaultModal from './CounterDefaultModal'

export default function CounterModal(props) {

    switch (props.data.TEMPLATE) {
        /*case 'gidrolockw646nbiot':
            return <Gidrolockw646nbiotModal {...props} />*/
        default:
            return <CounterDefaultModal {...props}/>
    }
}