import React from 'react'

import { Modal, Button } from 'react-bootstrap'

import ModalRow from './components/modalRow/ModalRow'
import LegendTimeout from '../shared/LegendTimeout'
import Signal from '../shared/Signal'

export default function DefaultDeviceModal(props) {
    const data = props.data

    return (
        <Modal show={Object.keys(data).length} onHide={props.handleClose} fullscreen={true}>
            <Modal.Header>
                <Modal.Title>Устройство {data.SERIAL_NUMBER}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='model-title'>{data.MODEL_TITLE}</p>
                <ModalRow title='IMEI' data={[data.IMEI]} />
                <ModalRow title='ICCID' data={[data.ICCID]} />
                <ModalRow title='Ревизия' data={[data.HW_VER]} />
                <ModalRow title='Версия ПО' data={[data.SW_VER]} />
                <br />
                <ModalRow title='Последний сеанс' show={data.REG_TIME ? true : false}>
                    {data.REG_TIME}
                    <LegendTimeout period={data.PERIOD} timeout={data.TIMEOUT} />
                </ModalRow>
                <ModalRow title='Сигнал' show={data.RSSI ? true : false}>
                    <Signal rssi={data.RSSI} snr={data.SNR} />
                </ModalRow>
                <ModalRow title='Температура' data={[data.TEMPERATURE ? Number(data.TEMPERATURE).toFixed(1) : null]} postfix=' °C' />
                <ModalRow title='Напряжение' data={[data.VOLTAGE]} postfix=' В' />
                <ModalRow title='Ресурс' data={[data.BATT ? Number(data.BATT).toFixed() : null]} postfix=' %' />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Закрыть
                </Button>
            </Modal.Footer>
        </Modal >
    )
}