import React from 'react'

import Icon from './icon/Icon'

function Render(props) {
    let signalParams = props?.rssi === null ? [] : [`RSSI: ${props.rssi} dBm`]
    signalParams.push(props?.snr === null ? null : `SNR: ${props.snr} dB`)
    signalParams.push(props?.band === undefined ? null : `B${props.band}`)

    return <><Icon {...props} />{signalParams.filter(Boolean).join(', ')}</>
}

export default function Signal(props) {
    if (props.rssi === null) {
        return null
    }

    const rssi = Number(props.rssi)
    const snr = Number(props.snr)

    if (rssi >= -80 && (props.snr === null || snr >= 20)) {
        return <Render className='signal-icons good' text='ОТЛИЧНЫЙ' {...props} />
    } else if (rssi >= -90 && (props.snr === null || snr >= 13)) {
        return <Render className='signal-icons good' text='ХОРОШИЙ' {...props} />
    } else if (rssi >= -100 && (props.snr === null || snr >= 0)) {
        return <Render className='signal-icons medium' text='УМЕРЕННЫЙ' {...props} />
    } else {
        return <Render className='signal-icons bad' text='ПЛОХОЙ' {...props} />
    }
}