import React from 'react'

import KvantSv15m from './kvantSv15m/KvantSv15m'

export default function Counter(props) {
    switch (props.TEMPLATE) {
        /*case 'nbiot649m':
            return <Nbiot649m {...props} />*/
        default:
            return <KvantSv15m {...props} />
    }
}