import React from 'react'

import { Modal, Button } from 'react-bootstrap'

import ModalRow from '../components/modalRow/ModalRow'
import LegendTimeout from '../../shared/LegendTimeout'
import Signal from '../../shared/Signal'

export default function CounterDefaultModal(props) {
    const data = props.data

    return (
        <Modal show={Object.keys(data).length} onHide={props.handleClose} fullscreen={true}>
            <Modal.Header>
                <Modal.Title>Прибор учета {data.CODE}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='model-title'>{data.MODEL_TITLE}</p>
                <ModalRow title='IMEI' data={[data.IMEI]} />
                <ModalRow title='ICCID' data={[data.ICCID]} />
                <br />
                <ModalRow title='Последний сеанс' show={data.REG_TIME ? true : false}>
                    {data.REG_TIME}
                    <LegendTimeout period={data.PERIOD} timeout={data.TIMEOUT} />
                </ModalRow>
                <ModalRow title='Сигнал' show={data.RSSI ? true : false}>
                    <Signal rssi={data.RSSI} snr={data.SNR} />
                </ModalRow>
                <ModalRow title='Последние показания' data={[`${data.FULL_COUNTER} ${data.EDIZM}`]} />
                <ModalRow title='Температура' data={[data.TEMPERATURE !== null ? Number(data.TEMPERATURE).toFixed(1) : null]} postfix=' °C' />
                <ModalRow title='Напряжение' data={[data.VOLTAGE_RADIO]} postfix=' В' />
                <ModalRow title='Ресурс' data={[data.BATTERY !== null ? Number(data.BATTERY).toFixed() : null]} postfix=' %' />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Закрыть
                </Button>
            </Modal.Footer>
        </Modal>
    )
}