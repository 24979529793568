import React from 'react'

import Gidrolockw646nbiot from './gidrolockw646nbiot/Gidrolockw646nbiot'
import Gidrolock653nidd from './gidrolock653nidd/Gidrolock653nidd'
import GidrolockC1 from './gidrolockC1/GidrolockC1'

export default function ValveNbiot(props) {
    switch (props.TEMPLATE) {
        /*case 'gidrolockw646Nbiot':
            return <Gidrolockw646 {...props} />*/
        case 'gidrolock653nidd':
            return <Gidrolock653nidd {...props} />
        case 'gidrolock_c1':
            return <GidrolockC1 {...props} />
        default:
            return <Gidrolockw646nbiot {...props} />
    }
}