import React from 'react'

import { Image } from 'react-bootstrap'

import imgOpen from './open.png'
import imgClose from './close.png'

export default function GidrolockState(props) {
    const img = props?.opened ? imgOpen : imgClose

    return (
        <Image className={props.className} src={img} />
    )
}