import React from 'react'

import { Modal, Button } from 'react-bootstrap'

import ModalRow from '../components/modalRow/ModalRow'
import LegendTimeout from '../../shared/LegendTimeout'
import Signal from '../../shared/Signal'
import Icon from '../../shared/icon/Icon'

import { reasonToIcons } from '../../shared/helpers'
import { sendCommand } from '../../../helpers/device'

export default function Gidrolockw646nbiot(props) {
    const data = props.data

    const is_open = 8
    const is_blocked = 32

    const states = (data.STATUS ?? []).map((item) => {
        return item.ICON ? <Icon className={item.ICON} text={item.DESCRIPTION} /> : null
    }).filter(Boolean)

    const reasons = reasonToIcons(data.REASON)

    const handleSendCommand = (command) => () => sendCommand(`${props.url}/${props.linkId}/command`, command)

    return (
        <Modal show={Object.keys(data).length} onHide={props.handleClose} fullscreen={true}>
            <Modal.Header>
                <Modal.Title>Запорная арматура {data.SERIAL_NUMBER}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='model-title'>{data.MODEL_TITLE}</p>
                <ModalRow title='IMEI' data={[data.IMEI]} />
                <ModalRow title='ICCID' data={[data.ICCID]} />
                <ModalRow title='Ревизия' data={[data.HW_VER]} />
                <ModalRow title='Версия ПО' data={[data.SW_VER]} />
                <br />
                <ModalRow title='Последний сеанс' show={data.REG_TIME ? true : false}>
                    {data.REG_TIME}
                    <LegendTimeout period={data.PERIOD} timeout={data.TIMEOUT} />
                </ModalRow>
                <ModalRow title='Сигнал' show={data.RSSI ? true : false}>
                    <Signal rssi={data.RSSI} snr={data.SNR} />
                </ModalRow>
                <ModalRow title='Температура' data={[data.TEMPERATURE !== null ? Number(data.TEMPERATURE).toFixed(1) : null]} postfix=' °C' />
                <ModalRow title='Напряжение' data={[data.VOLTAGE]} postfix=' В' />
                <ModalRow title='Ресурс' data={[data.BATT !== null ? Number(data.BATT).toFixed() : null]} postfix=' %' />
                <ModalRow title='Состояние' data={states} nextRow={states.length > 1 ? true : false} />
                <ModalRow title='Причина сеанса связи' data={reasons} nextRow={reasons.length > 1 ? true : false} />

                <br />

                <div className="d-grid gap-2">
                    {data.STATE & is_open ?
                        <Button onClick={handleSendCommand('close')} variant="secondary">Закрыть кран</Button> :
                        <Button onClick={handleSendCommand('open')} variant="secondary">Открыть кран</Button>}
                    {data.STATE & is_blocked ?
                        <Button onClick={handleSendCommand('enable')} variant="secondary">Разблокировать</Button> :
                        <Button onClick={handleSendCommand('disable')} variant="secondary">Заблокировать</Button>}
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Закрыть
                </Button>
            </Modal.Footer>
        </Modal>
    )
}