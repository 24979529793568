import React from 'react'

import { secondsToDhms } from '../../helpers/dateTime'

function Circle(props) {
    return <svg width="20" height="20">
        <circle r="9" cx="10" cy="10"
            fill={props.color} stroke="black" strokeWidth="1"
        />
    </svg>
}

export default function LegendTimeout(props) {
    if (props.period === null || props.timeout === null) {
        return null
    }

    const periodDhms = secondsToDhms(props.period)

    const timeout = Number(props.timeout)
    const period = Number(props.period)

    if (timeout <= period) {
        return <div><Circle color='green' /><i> (в пределах П) (П = {periodDhms})</i></div>
    } else if (props.timeout <= period * 3) {
        return <div><Circle color='yellow' /><i> (в пределах от П до П*3) (П = {periodDhms})</i></div>
    } else {
        return <div><Circle color='red' /><i> (более П * 3) (П = {periodDhms})</i></div >
    }
}