import React from 'react'

import { Image } from 'react-bootstrap'

import devImage from './kvant_sv_15m.png'
import SignalState from '../../../states/SignalState'

import styles from './kvant_sv_15m.module.css'

export default function KvantSv15m(props) {
    const signal = { rssi: props.RSSI, snr: props.SNR }
    const value = ('00' + props.FULL_COUNTER.toFixed(4)).slice(-8)

    return (
        <div className={styles.wrapper} onClick={props.onClick} style={{ top: `${props.TOP}%`, left: `${props.LEFT}%` }}>
            <span className={styles.indication}>{value}</span>
            <span className={styles.ind_wrapper}>
                <span className={styles.ind_value}>{value}</span>
                <span className={styles.ind_unit}>{<>М<sup>3</sup></>}</span>
            </span>
            <SignalState className={styles.signal} signal={signal} />
            <Image className={styles.img} src={devImage} />            
        </div>
    )
}