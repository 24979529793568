import React from 'react'

import { Image } from 'react-bootstrap'

import imgAlert from './alert.png'

export default function AlertState(props) {
    return (
        props.show ? <Image className={props.className} src={imgAlert} /> : null
    )
}