import React from 'react'

import ValveNbiotDefaultModal from './ValveNbiotDefaultModal'
import Gidrolockw646nbiotModal from './Gidrolockw646nbiotModal'
import Gidrolock653niddModal from './Gidrolock653niddModal'

import './valve_nbiot.css'

export default function ValveNbiotModal(props) {

    switch (props.data.TEMPLATE) {
        case 'gidrolockw646nbiot':
            return <Gidrolockw646nbiotModal {...props} />
        case 'gidrolock653nidd':
            return <Gidrolock653niddModal {...props} />
        default:
            return <ValveNbiotDefaultModal {...props}/>
    }
}