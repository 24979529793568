import React from 'react'

import './icons.css'

export default function Icon(props) {
    return (
        <div>
            <span {...props}></span>
            <i>{props.text}</i>
        </div>
    )
}