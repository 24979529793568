import React from 'react'

import { Image } from 'react-bootstrap'

import devImage from './gidrolockw646nbiot.png'
import GidrolockState from '../../../states/valveState/ValveState'
import SignalState from '../../../states/SignalState'

import styles from './gidrolock646nbiot.module.css'

export default function Gidrolockw646nbiot(props) {
    const signal = { rssi: props.RSSI, snr: props.SNR }
    const opened = Boolean(props.STATE & 8)

    return (
        <div className={styles.wrapper} onClick={props.onClick} style={{ top: `${props.TOP}%`, left: `${props.LEFT}%` }}>
            <GidrolockState className={styles.state} opened={opened} />
            <SignalState className={styles.signal}  signal={signal} />
            <Image className={styles.img} src={devImage} />
        </div>
    )
}