import './App.css'
import './preloader.css'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import Plan from './pages/Plan/Plan'

function App() {
  return (
    <>
      <div className="App">
        <Plan />
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="cssload-loader" id="loader" style={{visibility: "hidden"}}>
        <div className="cssload-inner cssload-one"></div>
        <div className="cssload-inner cssload-two"></div>
        <div className="cssload-inner cssload-three"></div>
        <div className="full-screen-splash-block"></div>
      </div>
    </>
  )
}

export default App
