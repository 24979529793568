import React, { useState, useEffect } from 'react'

import AQuery from '../../components/AQuery'
import DeviceModal from '../../components/modals/DeviceModal'

import LeakageSensor from '../../components/devices/leakageSensor/LeakageSensor'
import ValveNbiot from '../../components/devices/valveNbiot/ValveNbiot'
import Counter from '../../components/devices/counter/Counter'

import styles from './plan.module.css'

export default function Plan() {
  const queryParams = new URLSearchParams(window.location.search)

  const planId = queryParams.get('plan')

  const [timer, setTimer] = useState()

  const [deviceModal, setDeviceModal] = useState({})
  const handleShowDeviceModal = (url, linkId) => () => setDeviceModal({ url, linkId, show: true })
  const handleCloseDeviceModal = () => setDeviceModal({ show: false })

  const [state, setState] = useState({
    image: null,
    sensors: [],
    leakageSensors: [],
    valvesNbiot: [],
    counters: [],
  })

  useEffect(() => {
    return function cleanup() {
      clearInterval(timer)
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    clearInterval(timer)

    if (planId && !deviceModal.show) {
      loadData()
      setTimer(setInterval(loadData, 5000))
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceModal.show])

  async function loadData() {
    const response = await AQuery('GET', `plan/${planId}`)

    if (response) {
      setState({
        ...state,
        image: response.data.items.IMAGE,
        sensors: response.data.items.SENSORS ?? [],
        leakageSensors: response.data.items.LEAKAGE_SENSORS ?? [],
        valvesNbiot: response.data.items.VALVES_NBIOT ?? [],
        counters: response.data.items.COUNTERS ?? [],
      })
      document.title = response.data.items.TITLE ?? 'Kvant стенд'
    } else {
      setState({
        ...state,
        image: null,
        sensors: [],
        leakageSensors: [],
        valvesNbiot: [],
        counters: [],
      })
    }
  }

  return (
    planId ?
      <>
        <h3>Загрузка...</h3>
        <div className={styles.wrapper} style={{ backgroundImage: `url('${state.image}')` }}>

          {state.leakageSensors.map((item) => {
            return <LeakageSensor key={item.ID} {...item} onClick={handleShowDeviceModal('leakage-sensor', item.ID_LINK)} />
          })}

          {state.valvesNbiot.map((item) => {
            return <ValveNbiot key={item.ID} {...item} onClick={handleShowDeviceModal('valve-nbiot', item.ID_LINK)} />
          })}

          {state.counters.map((item) => {
            return <Counter key={item.ID} {...item} onClick={handleShowDeviceModal('counter', item.ID_LINK)} />
          })}
        </div>

        {deviceModal.show ?
          <DeviceModal
            {...deviceModal}
            handleClose={handleCloseDeviceModal}
          /> : null}
      </>
      : <h3>Квант-Энерго</h3>

  )
}
