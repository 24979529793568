import React from 'react'

import { Image } from 'react-bootstrap'

import devImage from './gidrolock_w1.png'
import AlertState from '../../../states/alertState/AlertState'

import styles from './gidrolock_w1.module.css'

export default function GidrolockW1(props) {
    const alert = Boolean(props.STATE & 64)

    return (
        <div className={styles.wrapper} onClick={props.onClick} style={{ top: `${props.TOP}%`, left: `${props.LEFT}%` }}>
            <AlertState className={styles.alert} show={alert} />
            <Image className={styles.img} src={devImage} />
        </div>
    )
}