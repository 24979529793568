import React from 'react'

import { Image } from 'react-bootstrap'

import devImage from './gidrolock_c1.png'
import GidrolockState from '../../../states/valveState/ValveState'
import styles from './gidrolock_c1.module.css'

export default function GidrolockC1(props) {
    const opened = Boolean(props.STATE & 16777216)

    return (
        <div className={styles.wrapper} onClick={props.onClick} style={{ top: `${props.TOP}%`, left: `${props.LEFT}%` }}>
            <GidrolockState className={styles.state} opened={opened} />
            <Image className={styles.img} src={devImage} />
        </div>
    )
}