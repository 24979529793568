import Icon from "./icon/Icon"

export function reasonToIcons(reason) {
    let icons = []

    if (reason & 1) icons.push(<Icon className='status-icons reset-power' text='перезагрузка по питанию' />)
    if (reason & 2) icons.push(<Icon className='status-icons reset-watchdog' text='перезагрузка по watchdog' />)
    if (reason & 4) icons.push(<Icon className='status-icons reset-button' text='перезагрузка по reset' />)
    if (reason & 8) icons.push(<Icon className='status-icons shedule' text='по расписанию' />)
    if (reason & 16) icons.push(<Icon className='status-icons magnet' text='по магнитной кнопке' />)
    if (reason & 32) icons.push(<Icon className='status-icons state-alert' text='событие' />)
    if (reason & 64) icons.push(<Icon className='status-icons settings' text='изменение параметров' />)

    return icons
}