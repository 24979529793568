import AQuery from "../components/AQuery"
import { toast } from 'react-toastify'
import { showLoader, hideLoader } from './loader'

export async function sendCommand(url, command) {
    showLoader()
    const response = await AQuery('POST', url, { command })
    const message = response?.data?.message ?? 'Ошибка отправки команды'

    if (response?.data?.status === 'ok') {
        toast.success(message)
    } else {
        toast.error(message)
    }
    hideLoader()
}