import React, { useState, useEffect } from 'react'

import AQuery from '../AQuery'

import CounterModal from './counter/CounterModal'
import LeakageSensorModal from './leakageSensor/LeakageSensorModal'
import ValveNbiotModal from './valveNbiot/ValveNbiotModal'
import DefaultDeviceModal from './DefaultDeviceModal'
import { showLoader, hideLoader } from '../../helpers/loader'

import './device_modal.css'

export default function DeviceModal(props) {
    let timer

    const [data, setData] = useState({})

    useEffect(() => {
        showLoader()
        loadData().then(hideLoader)                  // eslint-disable-next-line react-hooks/exhaustive-deps
        timer = setInterval(loadData, 5000)
        return function cleanup() {
            clearInterval(timer)
        }
    }, [])

    async function loadData() {
        const response = await AQuery('GET', `${props.url}/${props.linkId}`)

        if (response) {
            setData({
                ...response.data.items
            })
        } else {
            setData({})
        }
    }

    switch (props.url) {
        case 'counter':
            return <CounterModal data={data} {...props} />
        case 'leakage-sensor':
            return <LeakageSensorModal data={data} {...props} />
        case 'valve-nbiot':
            return <ValveNbiotModal data={data} {...props} />
        default:
            return <DefaultDeviceModal data={data} {...props} />
    }
}