import React from 'react'

import LeakageSensorDefaultModal from './LeakageSensorDefaultModal'

export default function ValveNbiotModal(props) {

    switch (props.data.TEMPLATE) {
        /*case 'gidrolock653nidd':
            return <Gidrolock653niddModal {...props} />*/
        default:
            return <LeakageSensorDefaultModal {...props}/>
    }
}